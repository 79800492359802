import React from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroBtnWrapper,
  HeroContent,
  HeroH1,
  HeroP,
  NavBtn,
  SocialIcons,
  SocialIconLink,
  SocialIcon,
  Anchor,
} from "./HerosectionElements";
import Video from "../videos/nebula 1.mp4";
import Discort from "../pics/discort.svg";
import twitter from "../pics/twitter.svg";
import opensea from "../pics/opensea-seeklogo.com 1.svg";

const Herosection = () => {
  const link = "https://www.premint.xyz/astrobotsociety";
  const mintPage = "/mint";

  return (
    <HeroContainer>
      <HeroBg>
        <video
          style={{
            width: "100%",
            height: "100%",
            OObjectFit: "cover",
            objectFit: "cover",
          }}
          playsInline
          autoPlay
          loop
          muted
          src={Video}
          type="video/mp4"
        ></video>
      </HeroBg>
      <HeroContent>
        <HeroH1>ASTROBOT SOCIETY</HeroH1>
        <HeroP>
          The Astrobot Society is a collection of 4,000 membership NFTs that
          give holders lifetime access to the{" "}
          <a
            href="https://tokenmetrics.com/"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecorationLine: "underline", color: "#FFCF30" }}
          >
            Token Metrics
          </a>{" "}
          platform.
        </HeroP>
        <HeroBtnWrapper>
          <Anchor href="https://opensea.io/collection/astrobot-society-by-token-metrics" target="_blank">
            Buy on OpenSea
          </Anchor>
        </HeroBtnWrapper>
        <NavBtn>
          <SocialIcons>
            {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook">
              {" "}
              <SocialIcon src={opensea}></SocialIcon>
            </SocialIconLink> */}
            <SocialIconLink
              href="https://twitter.com/AstrobotSociety"
              target="_blank"
              aria-label="twitter"
            >
              {" "}
              <SocialIcon src={twitter}></SocialIcon>
            </SocialIconLink>
            <SocialIconLink
              href="https://discord.com/invite/tokenmetricsofficial"
              target="_blank"
              aria-label="Facebook"
            >
              {" "}
              <SocialIcon src={Discort}></SocialIcon>
            </SocialIconLink>
          </SocialIcons>
        </NavBtn>
      </HeroContent>
    </HeroContainer>
  );
};
export default Herosection;
