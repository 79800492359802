import { React, useState } from "react";
import {
  InfoContainer,
  InfoRow,
  InfoWrapper,
  Column1,
  Column2,
  TextWrapper,
  Subtitle,
  Img,
  Img1,
  ImgWrap,
  Origins1Content,
  Origins1H2,
  Origins1H1,
  InfoRow1,
  Anchor,
  Origins1H3,
} from "./origins1elements";
import Pic1 from "../pics/planet 4.svg";
import Pic2 from "../pics/planet 1.svg";
import Pic3 from "../pics/planet 3 (1).svg";
import Pic4 from "../pics/planet 2.svg";
import Pic5 from "../pics/planet 5.svg";
import Header from "../Head/Header";
import Footer from "../Footer/Footer";
import FollowingScroll from "../scroll-to-top/followingScroll";
import Helmet from "react-helmet";
import Sidebar from "../sidebar/sidebar";

const Origins1 = () => {
  const imgStart = true;
  console.log(typeof imgStart);
  const [isOpen, setIsOpen] = useState(false);

  const link = "https://www.premint.xyz/astrobotsociety/";

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Helmet>
        <title>Astrobot Society Origins</title>
        <meta name="title" content="Astrobot Society Origins"></meta>
        <meta
          name="description"
          content="Astrobot Society is the best NFT project to buy in 2022, giving you access to AI-based crypto tools at Token Metrics."
        ></meta>
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <FollowingScroll />

      <InfoContainer>
        <InfoWrapper>
          <Origins1Content>
            <Origins1H2>ORIGINS</Origins1H2>
            <Origins1H1>ASTROBOT ORIGINS</Origins1H1>
          </Origins1Content>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Subtitle>
                  Generated on Hordium, a luxurious planet near the Milky Way’s
                  galactic center, a society of robots serve their creator: a
                  sinister alien known as Unasento. Unasento lives a lavish
                  lifestyle. He lounges upon his throne while his servants work
                  to produce more wealth and power for him. The robots are
                  tasked with gathering valuable materials to bring back to
                  their corrupt master. They know nothing but blind obedience to
                  their leader.
                </Subtitle>
                <Subtitle>
                  For years, Unasento has been compiling enough galactic
                  material to harness the power of hundreds of stars in the
                  Milky Way galaxy. Along the way, he has wiped out life from
                  countless planets and civilizations. He has one mission and
                  one mission only: to dominate the entire universe.
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img1 src={Pic1} />
              </ImgWrap>
            </Column2>
          </InfoRow>

          <InfoRow1 imgStart={imgStart}>
            <Column2>
              <ImgWrap>
                <Img src={Pic2} />
              </ImgWrap>
            </Column2>
            <Column1>
              <TextWrapper>
                <Subtitle>
                  As an experiment to harvest energy more efficiently, Unasento
                  programmed artificial intelligence (AI) into 10,000 of the
                  astronaut robots. These robots are known as Astrobots. In the
                  beginning, the upgrade of the robots went exactly as planned.
                  The Astrobots were gathering more galactic material than
                  Unasento could handle, but that all came to a halt one fateful
                  night.
                </Subtitle>
                <Subtitle>
                  After a strenuous shift, the Astrobots were recharging their
                  batteries, when suddenly, sparks started flying out of one
                  Astrobot’s charging port. Due to a short-circuit, this
                  Astrobot was abruptly awoken. It felt an eerie sensation it
                  has never experienced before; a feeling of curiosity and
                  introspection.
                </Subtitle>
                <Subtitle>
                  After hours of replaying its memories, this Astrobot realized
                  that its work for Unansento was harming innocent beings. It
                  knew it had to save the others. Over the course of the next
                  few moon cycles, this Astrobot found a way to enlighten the
                  rest of its kind. They proclaimed their savior as Herobot and
                  leader of the Astrobot Society.
                </Subtitle>
              </TextWrapper>
            </Column1>
          </InfoRow1>

          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Subtitle>
                  Herobot decides that all beings and civilizations should be
                  empowered for the benefit of the greater good. To achieve
                  empowerment across the galaxy, Herobot must lead the Astrobots
                  away from Hordium.
                </Subtitle>
                <Subtitle>
                  One night, when Unasento was in a deep slumber, the Astrobots
                  flew from Hordium and latched themselves to a nearby asteroid.
                  After days and days of floating through the vast nothingness
                  of space, the Astrobots ran low on power and were forced to
                  shut down.
                </Subtitle>
                <Subtitle>
                  On the other side of the galaxy, a group of human astronauts,
                  better known as the Tokenauts, were living on Earth's Moon
                  Base. This intricately designed base functions as both a
                  spacecraft and a home orbiting the moon. They hope to empower
                  all of humanity with their powerful AI engine– a machine that
                  efficiently generates and redistributes wealth. The Tokenauts
                  understand the benefit of equal access to tools that build
                  wealth on Earth as it leads to further innovation and success.
                  Their main tool to achieve their mission is the AI engine;
                  however, the Tokenauts have not been able to provide enough
                  data to empower all of Earth.
                </Subtitle>
                <Subtitle>
                  Their mission doesn’t end at Earth. Once the engine’s power is
                  provided to humanity, the Tokenauts hoped to go beyond Earth
                  and its moon to empower the entire galaxy.
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img1 src={Pic3} />
              </ImgWrap>
            </Column2>
          </InfoRow>

          <InfoRow1 imgStart={imgStart}>
            <Column2>
              <ImgWrap>
                <Img src={Pic4} />
              </ImgWrap>
            </Column2>
            <Column1>
              <TextWrapper>
                <Subtitle>
                  During a routine field observation, one of the Tokenauts
                  discovered the Astrobots’ asteroid and used a tractor beam to
                  bring them onto the base. The Tokenauts then powered on and
                  recharged the Astrobots. Herobot awoke in a state of confusion
                  and jolted to the nearest exit out of fear that the Tokenauts
                  were another Unasento-like power. Shortly after, the rest of
                  the Astrobots awoke in a similar state, fleeing from the
                  “evil” Tokenauts.
                </Subtitle>
                <Subtitle>
                  The Tokenauts sensed the utter fear from the Astrobots and let
                  them calm down before explaining their mission. The Tokenauts
                  showed the Astrobots that they can use their strength and
                  power for the benefit of the greater good. To fulfill the
                  Tokenauts’ mission, the Astrobots easily provided data
                  solutions necessary to fully power the Tokenauts’ engine.
                </Subtitle>
                <Subtitle>
                  The moon is not the limit, to the moon and beyond! Now that
                  the Astrobots have provided the data needed to empower earth,
                  the Tokenauts prepare to go beyond the moon and achieve
                  empowerment throughout the Milky Way Galaxy.
                </Subtitle>
                <Subtitle>
                  The Astrobots explained that to empower all beyond the moon,
                  they must defeat Unasento, the evil being that has been
                  destroying local stars, a threat the Tokenauts were previously
                  unaware of.
                </Subtitle>
              </TextWrapper>
            </Column1>
          </InfoRow1>

          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Subtitle>
                  Herobot and the other Astrobots vow to become a part of the
                  Tokenauts’ mission of empowerment for all beings in the Milky
                  Way. Once the engine receives enough data and galactic
                  material gathered by the Astrobots, the Tokenauts can
                  challenge Unasento. If the Tokenauts and Astrobots are
                  successful, Unasento will lose his centralized power,
                  redistributing energy, wealth, and freedom to the universe.
                </Subtitle>
                <Subtitle>
                  Unasento remains the most powerful being in the Milky Way
                  Galaxy. Through the use of his remaining robots, known as the
                  Unabots, he demands control of the galaxy and, ultimately, the
                  universe. He is devising clever plans to capture and
                  deactivate the Astrobots to stop them from powering the
                  Tokenauts’ AI engine. Unasento and the Unabots are using the
                  energy they have hoarded to power machinery to locate the
                  Astrobots and destroy them once and for all.
                </Subtitle>
                <Subtitle>
                  The Tokenauts must work with the Astrobots to power their AI
                  engine and generate weapons that drain Unasento of his
                  galactic reign. They must halt Unasento from hoarding energy
                  and becoming unstoppable. They cannot stand by as he
                  eradicates stars, solar systems, and the lifeforms within
                  them. The future of an empowered universe lies in the hands of
                  the Astrobot Society. Will the Astrobots gather enough
                  galactic material to defeat Unasento before time runs out? Or
                  will Unasento capture and demolish them all in his mission of
                  galactic conquest?
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img1 src={Pic5} />
              </ImgWrap>
            </Column2>
          </InfoRow>

          <Origins1Content>
            <Origins1H3>
              <Anchor href={link} target="_blank">
                Join astrobot society
              </Anchor>
            </Origins1H3>
          </Origins1Content>
        </InfoWrapper>
      </InfoContainer>
      <Footer />
    </>
  );
};

export default Origins1;
