import React from 'react'
import { useState } from 'react'
import Header from '../Head/Header'
import Herosection from '../HeroSection/Herosection'
import Sidebar from '../sidebar/sidebar'
import Team from '../Team/Team'
import Footer from '../Footer/Footer'
import Origins from '../origins/origins'
import FAQ from '../FAQ/FAQ'
import FollowingScroll from '../scroll-to-top/followingScroll'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
      setIsOpen(!isOpen)
  }
  return (
    <>
     <FollowingScroll />
     <Sidebar isOpen = {isOpen} toggle = {toggle}/>
        <Header toggle={toggle}/>
        <Herosection />
        <Team />
        <Origins />
        <FAQ />
        <Footer />
    </>
  )
}

export default Home