import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavItem,
  NavLinks,
  NavMenu,
  MobileIcon,
  NavBtn,
  NavBtnLink,
  NavLogoimage,
  NavLinks1,
  SocialIcon,
  SocialIconLink,
  SocialIcons,
} from "./Headerelements";
import { FaBars } from "react-icons/fa";
import Logo from "../pics/Logo.svg";
import Discort from "../pics/discort.svg";
import twitter from "../pics/twitter.svg";
import opensea from "../pics/opensea-seeklogo.com 1.svg";
import { useState } from "react";
import { useEffect } from "react";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";

const Header = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  });
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <NavLogoimage src={Logo} />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              {/* <NavItem>
                <NavLinks to="/#moonmap">MOONMAP</NavLinks>
              </NavItem> */}
              <NavItem>
                <NavLinks to="/#team">TEAM</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks1 to="/origins" offset={-80}>
                  ORIGINS
                </NavLinks1>
              </NavItem>
              <NavItem>
                <NavLinks to="/#faq" offset={-260}>
                  FAQ
                </NavLinks>
              </NavItem>
              {/*<NavItem>*/}
              {/*  <NavLinks1 to="/mint">MINT</NavLinks1>*/}
              {/*</NavItem>*/}
            </NavMenu>
            <NavBtn>
              <SocialIcons>
                <SocialIconLink
                  style={{ visibility: "hidden" }}
                  href="/"
                  target="_blank"
                  aria-label="Facebook"
                >
                  {" "}
                  <SocialIcon src={opensea}></SocialIcon>
                </SocialIconLink>
                <SocialIconLink
                  href="https://twitter.com/AstrobotSociety"
                  target="_blank"
                  aria-label="twitter"
                >
                  {" "}
                  <SocialIcon src={twitter}></SocialIcon>
                </SocialIconLink>
                <SocialIconLink
                  href="https://discord.com/invite/tokenmetricsofficial"
                  target="_blank"
                  aria-label="Facebook"
                >
                  {" "}
                  <SocialIcon src={Discort}></SocialIcon>
                </SocialIconLink>
              </SocialIcons>
            </NavBtn>
            {/* <NavBtn><NavBtnLink to="/">CONNECT </NavBtnLink></NavBtn> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Header;
