import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { HashLink as LinkS } from "react-router-hash-link";
import { Link as LinkR } from "react-router-dom";

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 19;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: grid;
  align-items: left;
  text-align: left;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const SidebarContainerInside = styled.div`
  width: 80%;
  position: relative;
  height: 100%;
  background: black;
  top: 15%;
  left: 0;
  align-items: left;
  border: 2px solid red;
  text-align: left;
  /* z-index:20 ; */
  /* display:grid ; */
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
export const SidebarWrapper = styled.div`
  color: #fff;
`;

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  margin-left: 60px;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 70px);
  text-align: center;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    height: 500px;
  }

  @media screen and (max-width: 480px) {
    height: 430px;
    grid-template-rows: repeat(6, 60px);
  }
`;

export const SidebarRoute = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  margin-left: 60px;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  margin-bottom: 20px;
`;

export const SocialIcon = styled.img`
  height: 32px;
  width: 30px;
  margin-bottom: 10px;
`;

export const LogoIcon = styled.img`
  height: 70px;
  width: 250px;
  margin-bottom: 10px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const HorizontalLine = styled.hr`
  width: 80%;
  height: 5px;
  border: 0 none;
  margin-right: auto;
  margin-left: auto;
  color: #93d3ff;
  margin-top: 25px;
  margin-bottom: 25px;
  background: #93d3ff;
`;
