import styled from "styled-components";
// import  {HashLink as LinkR} from 'react-router-hash-link'
import { HashLink as LinkS } from "react-router-hash-link";
import { Link as LinkR } from "react-router-dom";

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "black" : "transparent")};
  height: 100px;
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0px 24px;
  max-width: 1300px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  /* margin-left:24px; */
  font-weight: bold;
  text-decoration: none;
`;

export const NavLogoimage = styled.img`
  /* width:70px ;
    height:70px; */
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  margin-bottom: 0rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 50px;
  margin-right: 20px;

  @media screen and (max-width: 1000px) {
    margin-right: 10px;
  }
`;

export const NavLinks = styled(LinkS)`
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 18px; */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    padding: 10px;
  }

  &:active {
    color: #ffcf30;
  }

  &:hover {
    color: #ffcf30;
  }
`;

export const NavLinks1 = styled(LinkR)`
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 18px; */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    padding: 0;
  }

  &.active {
    color: #ffcf30;
  }
  &:hover {
    color: #ffcf30;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  background: transparent;
  padding: 10px 22px;
  color: white;
  outline: none;
  border: 3px solid #93d3ff;
  cursor: pointer;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;

  @media screen and (max-width: 940px) {
    width: 120px;
  }
`;

export const SocialIcon = styled.img`
  height: 32px;
  width: 30px;

  @media screen and (max-width: 940px) {
    width: 25px;
  }

  &:hover {
    height: 38px;
    width: 35px;
  }
  /* margin-bottom:10px; */
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;
