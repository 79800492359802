import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(28, 18, 52, 0) 0%,
    #1c1234 0px,
    #1c1234 0%
  );
  @media screen and (max-width: 768px) {
    padding: 100px, 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flow-root;
  z-index: 1;
  height: fit-content;
  width: 100%;
  max-width: 1320px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1" "col2";
  }
`;
export const InfoRow1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1" "col2";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  width: 780px;

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;
export const Column2 = styled.div`
  /* margin-bottom:15px; */
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  padding-top: 0;
`;
export const Subtitle = styled.p`
  margin-bottom: 15px;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 17.0013px;
`;

export const ImgWrap = styled.div`
  max-width: 455px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0px;
  /* height: 390px; */
  /* margin-bottom: 5rem; */
`;

export const Img1 = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0px;
  /* height: 390px; */
  margin-bottom: 5rem;
`;

export const Origins1H1 = styled.h1`
  color: #fff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  text-align: left;
  margin-bottom: 0rem;

  @media screen and (max-width: 1442px) {
    font-size: 32px;
    position: absolute;
    top: 6rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 32px;
    position: absolute;
    top: 6rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    position: absolute;
    top: 6rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Origins1H2 = styled.h1`
  color: #fff;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  margin-top: 9rem;
  font-size: 20px;
  text-align: left;
  color: #93d3ff;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Origins1Content = styled.div`
  padding: 0 0 0 12px;
  height: 4rem;
  justify-content: center;
  align-items: center;
`;

export const Anchor = styled.a`
  box-sizing: border-box;
  width: 271px;
  height: 51px;
  background: #93d3ff;
  border: 3px solid #93d3ff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
`;

export const Origins1H3 = styled.h1`
  color: #fff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  text-align: -webkit-center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
