import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { IoChevronUp } from "react-icons/io5";

import styled from "styled-components";

const FollowingScroll = () => {
  const [isVisible, setIsVisible] = useState(false);
  const listenToScroll = () => {
    let heightToHidden = 250;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);
  return (
    <Wrapper>
      {isVisible && (
        <div className="top-btn" onClick={goToBtn}>
          <IoChevronUp className="top-btn--icon" />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  .top-btn {
    font-size: 1.8rem;
    font-weight: 600;
    width: 3rem;
    height: 3rem;
    color: black;
    background-color: #93d3ff;
    box-shadow: red;
    border-radius: 50%;
    position: fixed;
    bottom: 4.8rem;
    right: 4.8rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1130px) {
      bottom: 4rem;
      right: 4rem;
    }

    @media screen and (max-width: 1050px) {
      bottom: 3rem;
      right: 3rem;
    }

    @media screen and (max-width: 520px) {
      bottom: 2rem;
      right: 2rem;
    }
  }

  &--icon {
    animation: gototop 1.2s linear infinite alternate-reverse;
  }
  @keyframes gototop {
    0% {
      transform: translate(-0.5rem);
    }
    100% {
      transform: translate(-1rem);
    }
  }
`;

export default FollowingScroll;
