import styled from "styled-components";
import { Link } from "react-router-dom";

export const TeamContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(13, 0, 50, 0) 0%,
    #0d0032 0%,
    #0d0032 100%
  );

  @media screen and (max-width: 1280px) {
    height: 550px;
  }

  @media screen and (max-width: 480px) {
    height: 625px;
  }
`;

export const TeamWrapper = styled.div`
  max-width: 1500px;
  margin: 8px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  @media screen and (max-width: 1360px) {
    padding: 0;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: table-column !important ;
  }

  @media screen and (max-width: 1280px) {
    display: block;
  }
`;

export const TeamCard = styled.div`
  background: transparent;
  display: column;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  height: 279px;
  padding: 2rem;
  width: 400px;

  @media screen and (max-width: 1280px) {
    width: 100%;
    height: auto;
    text-align: center;
  }
  /* border:4px solid #93D3FF; */
`;

export const TeamCard3 = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1px;
  max-height: 390px;
  padding: 3px;
`;

export const TeamCard1 = styled.div`
  background: rgba(147, 211, 255, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 850px;
  height: 250px;
  grid-gap: 4rem;
  padding: 3rem;
  border: 2px solid #93d3ff;

  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 0 auto;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
    height: auto;
    padding: 2rem;
    grid-gap: 1rem;
  }
`;

export const TeamIcon = styled.img`
  margin-bottom: 10px;

  @media screen and (max-width: 740px) {
    margin-bottom: 0;
  }
`;

export const TeamH1 = styled.h1`
  font-size: 44px;
  color: #fff;
  margin-bottom: 2rem;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;

  @media screen and (max-width: 1280px) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 740px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 36px;
    line-height: 3rem;
  }
`;

export const TeamH3 = styled.h1`
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  font-family: "IBM Plex Mono";
  color: #93d3ff;

  @media screen and (max-width: 480px) {
    font-size: 20px;
    line-height: 3rem;
  }
`;

export const TeamH2 = styled.h2`
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: white;
  margin-bottom: 2px;

  @media screen and (max-width: 740px) {
    text-align: center;
  }
`;

export const TeamLogo = styled(Link)`
  color: #fff;
  margin-bottom: -13px;
  justify-self: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const TeamBtnWrapper = styled.div`
  margin-top: -16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
